import React, { useEffect, useState } from "react";
import "./workspacesupport.scss";
import AckBox from "./ack-box/ack-box";
import EquipmentBox from "./equipment-box/equipment_box";
import { Floor, Space } from "../models/Asset";
import { ProblemCode } from "../models/Equipment";
import ReportBox from "./report-box/report_box";
import Alert from "react-bootstrap/Alert";
import { getBaseUrl } from "./../utils/Constant";
import { Buffer } from "buffer";
import { renderEmpty } from "../utils/components";

const headers = new Headers({
  Authorization: `Basic ${Buffer.from(
    `${getBaseUrl().api_uname}:${getBaseUrl().api_passwd}`
  ).toString("base64")}`,
});

const WorkspaceSupport: React.FC = (props: any): JSX.Element => {
  const [showEquipmentBox, setShowEquipmentBox] = useState<boolean>(false);
  const [space, setSpace] = useState<Space | Floor | undefined>(undefined);
  const [problemCode, setProblemCode] = useState<ProblemCode | undefined>(
    undefined
  );
  const [showReportBox, setShowReportBox] = useState<boolean>(false);
  const [showAcknowledgementBox, setShowAcknowledgementBox] =
    useState<boolean>(false);
  const [acknowledgementStatus, setAcknowledgementStatus] =
    useState<boolean>(false);
  const [isDeskReportFlag, setIsDeskReportFlag] = useState<Boolean>(false);
  const [error, setError] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [reportButtonText, setReportButtonText] = useState<string>(
    "Report this problem"
  );
  const [selectedLocationCode, setSelectedLocationCode] = useState<any>(null);
  const [ackBoxClosed, setAckBoxClosed] = useState<boolean>(false);

  useEffect(() => {
    ackBoxClosed && setReportButtonText("Report this problem");
  }, [ackBoxClosed]);

  const getProblemCode = () => {
    if (
      props.locationCode &&
      props.source === "workplace-app" &&
      props.id !== null &&
      props.id !== ""
    ) {
      if (props.isFloorSearchSelected.toLowerCase().includes("true"))
        searchFloor(props.id);
      else if (
        !props.id.toUpperCase().includes(props.locationCode.toUpperCase())
      )
        searchSpace(props.id, "search-by-qr-code");
      else searchSpace(props.id, "search-by-space");
    } else if (props.match.params.id) {
      searchSpaceByQrCode(props.match.params.id);
    } else {
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    if (ackBoxClosed) {
      getProblemCode();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ackBoxClosed]);

  useEffect(() => {
    getProblemCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchSpaceByQrCode = (qrCodeId: string) => {
    if (!/^\d+$/.test(qrCodeId) && qrCodeId.length === 8)
      searchFloor(props.match.params.id);
    else if (!/^\d+$/.test(qrCodeId) && qrCodeId.length !== 8)
      searchSpace(props.match.params.id, "search-by-space");
    else searchSpace(props.match.params.id, "search-by-qr-code");
  };

  const stopAllViews = () => {
    setShowEquipmentBox(false);
    setShowReportBox(false);
    setShowAcknowledgementBox(false);
  };

  const searchCallback = (space: Space) => {
    if (space) {
      stopAllViews();
      setSpace(space);
      setShowEquipmentBox(true);
    }
  };

  const searchCallbackFloor = (floor: Floor) => {
    if (floor) {
      stopAllViews();
      setSpace(floor);
      setShowEquipmentBox(true);
    }
  };

  const handleDeskReport = (flag: Boolean) => {
    setIsDeskReportFlag(flag);
  };

  const equipmentCallback = (problemCode: ProblemCode) => {
    if (problemCode) {
      stopAllViews();
      setProblemCode(problemCode);
      setShowReportBox(true);
    }
  };

  const renderEquipmentBox = () => {
    return (
      <EquipmentBox
        space={space}
        callback={equipmentCallback}
        isExtView={false}
        isDeskReportFlag={isDeskReportFlag}
        source={props.source || ""}
        locationCode={props.locationCode || ""}
        theme={props.theme || "light"}
        mail={props.mail || ""}
        setAckBoxClosed={setAckBoxClosed}
      />
    );
  };

  const backToSearch = () => {
    let test = "problemReported";
    if (props.source && props.source === "workplace-app")
      window.location.assign(
        `/web?source=workplace-app&theme=${props.theme}&mail=${props.mail}&locationCode=${props.locationCode}&problemReported=${test}`
      );
    else window.location.assign(`/`);
  };

  const backToEquipmentBox = () => {    
    stopAllViews();
    setShowEquipmentBox(true);
  };

  const reportCallback = (status: boolean) => {
    setShowAcknowledgementBox(status);
    setAcknowledgementStatus(status);
  };

  const renderReportBox = () => {
    return (
      <ReportBox
        space={space}
        problemCode={problemCode}
        callback={reportCallback}
        back={backToEquipmentBox}
        isDeskReportFlag={isDeskReportFlag}
        source={props.source || ""}
        locationCode={props.locationCode || ""}
        selectedLocationCode={selectedLocationCode || ""}
        theme={props.theme || "light"}
        mail={props.mail || ""}
        reportButtonText={reportButtonText}
        setReportButtonText={setReportButtonText}
        isFloorSearchSelected={props.isFloorSearchSelected || false}
      />
    );
  };

  const renderAcknowledgementBox = () => {
    return (
      <AckBox
        ack={acknowledgementStatus}
        back={backToSearch}
        setReportButtonText={setReportButtonText}
        setAckBoxClosed={setAckBoxClosed}
      />
    );
  };

  const searchSpace = (id: string, type: string) => {    
    if(!id.includes('verify')){
    let url =
      type === "search-by-qr-code"
        ? `${getBaseUrl().api_base_url}/search/qr-code/${id}`
        : `${getBaseUrl().api_base_url}/search/space/${id}`;
    fetch(url, {
      headers,
    })
      .then((response) => {
        if (response.status === 404) {
          throw new Error("No results");
        } else if (response.status === 400) {
          throw new Error(
            "This space or category is disabled for fault reporting"
          );
        } else if (!response.ok) {
          throw new Error("An error occurred");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data === undefined || data === null) {
          setError("No results");
          setShowError(true);
          return;
        }
        data.spaceId.includes("DESK")
          ? handleDeskReport(true)
          : handleDeskReport(false);
        let space: Space = {
          spaceId: data.spaceId,
          spaceName: data.spaceName,
          spaceCategoryId: data.spaceCategoryId,
          spaceFunction: data.spaceFunction,
          spaceType: data.spaceType,
          spaceSubType: data.spaceSubType,
          problemCodeList: [],
        };

        data?.locationCode && setSelectedLocationCode(data?.locationCode);

        fetch(
          `${getBaseUrl().api_base_url}/search/space/${
            space.spaceId
          }/problem-codes`,
          { headers }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("An error occurred");
            } else {
              return response.json();
            }
          })
          .then((data) => {
            let pcList: ProblemCode[] = [];
            for (let pc of data) {
              let _pc: ProblemCode = {
                spaceProblemCodeId: pc.spaceProblemCodeId,
                problemCode: pc.problemCode,
                problemCodeDescription: pc.problemCodeDescription,
                locationCode: pc.locationCode,
                markedForFaultReporting: pc.markedForFaultReporting,
                faultDescription: pc.faultDescription,
                faultReportingEnabled: pc.faultReportingEnabled,
                reportingTimestamp: pc.reportingTimestamp,
                extStatus: pc.extStatus,
                vendorName: pc.vendorName,
              };
              pcList.push(_pc);
            }
            space.problemCodeList = pcList;
            searchCallback(space);
          })
          .catch((error) => {
            setError(`${error.message}`);
            setShowError(true);
            return;
          });
      })
      .catch((error) => {
        setError(`${error.message}`);
        setShowError(true);
      });
    }
  };

  const searchFloor = (id: string) => {
    let url = `${getBaseUrl().api_base_url}/search/location/${id.slice(
      0,
      4
    )}/building/${id.slice(0, 6)}/floor/${id.slice(-2)}`;
    fetch(url, {
      headers,
    })
      .then((response) => {
        if (response.status === 404) {
          throw new Error("No results");
        } else if (response.status === 400) {
          throw new Error("This floor is disabled for fault reporting");
        } else if (!response.ok) {
          throw new Error("An error occurred");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data === undefined || data === null) {
          setError("No results");
          setShowError(true);
          return;
        }

        let floor: Floor = {
          locationCode: data.locationCode,
          spaceId: id,
          floor: data.floor,
          frEnabled: data.frEnabled,
          problemCodeList: [],
        };

        floor?.locationCode && setSelectedLocationCode(floor?.locationCode);

        fetch(
          `${getBaseUrl().api_base_url}/search/location/${
            floor.locationCode
          }/building/${data.buildingCode}/floor/${id.slice(-2)}/problem-codes`,
          { headers }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("An error occurred");
            } else {
              return response.json();
            }
          })
          .then((data) => {
            let pcList: ProblemCode[] = [];
            for (let pc of data.problemCodesMappedWithFloor) {
              let _pc: ProblemCode = {
                spaceProblemCodeId: pc.problemCodeId,
                problemCode: pc.problemCode,
                problemCodeDescription: pc.problemCodeDescription,
                locationCode: pc.locationCode,
                markedForFaultReporting: pc.markedForFaultReporting,
                faultDescription: pc.faultDescription,
                faultReportingEnabled: pc.faultReportingEnabled,
                reportingTimestamp: pc.reportingTimestamp,
                extStatus: pc.extStatus,
                vendorName: pc.vendorName,
              };
              pcList.push(_pc);
            }
            floor.problemCodeList = pcList;
            searchCallbackFloor(floor);
          })
          .catch((error) => {
            setError(`${error.message}`);
            setShowError(true);
            return;
          });
      })
      .catch((error) => {
        setError(`${error.message}`);
        setShowError(true);
      });
  };

  const renderErrorMessage = () => {
    return (
      <Alert variant="danger" className="error-alert">
        {error}
      </Alert>
    );
  };

  return (
    <div className="app-container">
      {(showEquipmentBox || ackBoxClosed) && renderEquipmentBox()}
      {showReportBox && !ackBoxClosed && renderReportBox()}
      {showAcknowledgementBox && renderAcknowledgementBox()}
      {showError && renderErrorMessage()}
      {isEmpty && props.problemReported === "" && renderEmpty()}
      <br />
      <br />
    </div>
  );
};

export default WorkspaceSupport;
