export class Issue {

    issueId: string | undefined
    equipmentId: string | undefined
    equipmentDisplayName: string | undefined
    assetUniqueId: string | undefined
    issueDetails: string | undefined
    base64Image: string | undefined
    issueReportedOn: string | undefined
    supportOrgEmail: string | undefined
    reporteeEmail: string | undefined
    isSupportOrgNotified: boolean | undefined
    
}
  
export class Fault {
  problemCode: string | undefined;
  space_: string | undefined;
  faultDescription: string | undefined;
  reportedBy: string | undefined;
  imageBase64: string | undefined;
  isConfidentialFaultDescription: boolean | undefined;
  deviceID:string|undefined;
}

export type FaultVerificationType = {
  id: string;
  deviceID: string;
  codeEntered: string;
  email?:string;
};


  