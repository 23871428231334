/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import "./ack-box.scss";
import { Dialog } from "@dsi/react-eds";

type Props = {
  ack: boolean;
  back: () => void;
  setReportButtonText: (reportButtonText: string) => void;
  setAckBoxClosed: (status: boolean) => void;
  ackBoxText?: string;
};

const AckBox = (props: Props): JSX.Element => {
  const [smallDialogOpen, setSmallDialogOpen] = useState(true);

  let title1 = "Something went wrong";
  let message =
    "An error was encountered while reporting this problem. Please contact the administrator";
  let footer = "Server error";
  let alert = "danger";

  if (props.ack) {
    title1 = props?.ackBoxText ||  "The problem has been reported";
    message =
      "The support team concerned will start working on this shortly. You will be notified with an email about the details of the problem reported";
    footer = "The support team may reach out to you if necessary";
    alert = "warning";
  }

  const renderSuccess = () => {
    return <div className="success-logo"></div>;
  };

  const renderFailure = () => {
    return <div className="failed-logo"></div>;
  };

  return (
    <div id="ack-1" className="ackDialogBox">
      <Dialog
        key="ack-box-id"
        show={smallDialogOpen}
        title=""
        onClose={() => setSmallDialogOpen(false)}
      >
        <i
          className="icon icon-cross"
          style={{ float: "right" }}
          onClick={() => {
            setSmallDialogOpen(false);
            props.setReportButtonText("Problem reported");
            props.setAckBoxClosed(true);
          }}
        ></i>
        <br />
        <br />
        {props.ack ? renderSuccess() : renderFailure()}
        <br />
        <br />
        {title1}
      </Dialog>
    </div>
  );
};

export default AckBox;
