import React, { useEffect } from "react";
import {
    useTheme
} from "@dsi/react-eds";

type Props = {
    _theme: string;
};

const AppThemeChanger: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [theme, setTheme] = useTheme();
    useEffect(() => {
        if (props && props._theme === 'dark') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
        // console.log(theme);
    }
    );
    return (<></>);
}
export default AppThemeChanger;
