import { Button, Loading } from "@dsi/react-eds";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  getAllEmailDomains,
  reSubmitFault,
  validateLinkAndSubmitFault,
} from "../../api/api";
import faultReportingImg from "../../images/fault_report_hero.png";
import { FaultVerificationType } from "../../models/Issue";
import { DEVICE_EMAIL_PAIR_ATTRIBUTE } from "../../utils/Constant";
import "./verify.scss";
export const Verify = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const queryParam = history.location.search;
  const params = new URLSearchParams(queryParam);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [linkExpiredStatus, setLinkExpiredStatus] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [allDomains, setAllDomains] = useState<any>([]);
  const faultID = params.get("id")?.toString() || "";
  const deviceID = params.get("device")?.toString() || "";
  const codeEntered = params.get("code")?.toString() || "";
  useEffect(() => {
    fetchData();
    async function fetchData() {
      setLinkExpiredStatus(false);
      try {
        setIsLoading(true);
        let faultVerificationDetails: FaultVerificationType = {
          id: faultID,
          deviceID: deviceID,
          codeEntered: codeEntered,
        };
        console.log("faultVerificationDetails", faultVerificationDetails);
        const response = await validateLinkAndSubmitFault(
          faultVerificationDetails
        );
        console.log("result", response.result);
        if (!response.is_error && response.result) {
          if (
            response.result.isValid === true &&
            response.result.ttlUtc === -1
          ) {
            setStatusMessage("Fault is already reported!");
          } else if (
            response.result.isValid === true &&
            response.result.ttlUtc !== -1
          ) {
            setStatusMessage(
              "Fault reported successfully! Your device is now validated for the next 30 days."
            );
            const newDeviceEmailPairAttribute = {
              emailId: response.result.userEmail,
              deviceId: deviceID,
              ttlUtc: response.result.ttlUtc,
            };
            localStorage.setItem(
              DEVICE_EMAIL_PAIR_ATTRIBUTE,
              JSON.stringify(newDeviceEmailPairAttribute)
            );
          } else {
            if (response.result.expiredStatus === false) {
              setStatusMessage(
                "This fault is already reported from this space or floor"
              );
            } else {
              const getDomains = await getAllEmailDomains();
              setAllDomains(getDomains.result);
              setLinkExpiredStatus(true);
              setStatusMessage(
                "Your link has expired, if you still want to report the fault, you need to re-enter your email!"
              );
            }
          }
        }
      } catch (err) {
        setStatusMessage("Error in reporting fault!");
      } finally {
        setIsLoading(false);
      }
    }
  }, [codeEntered, deviceID, faultID]);

  const reSubmitExpiredLinkFault = async () => {
    setIsLoading(true);
    setShowError(false);

    try {
      let faultDetails: FaultVerificationType = {
        id: faultID,
        deviceID: deviceID,
        codeEntered: codeEntered,
        email: userEmail,
      };
      const response = await reSubmitFault(faultDetails);
      if (response && !response.is_error && response.result) {
        if (response.result.isValid === true) {
          setLinkExpiredStatus(false);
          setStatusMessage("Fault resubmitted successfully!");
          const newDeviceEmailPairAttribute = {
            emailId: userEmail,
            deviceId: deviceID,
            ttlUtc: response.result.ttlUtc,
          };
          localStorage.setItem(
            DEVICE_EMAIL_PAIR_ATTRIBUTE,
            JSON.stringify(newDeviceEmailPairAttribute)
          );
        } else {
          setStatusMessage("Error in reporting fault!");
        }
      }
    } catch (err) {
      setStatusMessage("Error in reporting fault!");
    } finally {
      setIsLoading(false);
    }
  };

  const validate = async () => {
    const regexForEmail = /[!#$%^&*()+=[\]{};':"\\|,<>/? ]/g;
    if (userEmail.length === 0) {
      setError(
        "Email is mandatory, enter your Ericsson email or Ericsson partner email"
      );
      setShowError(true);
      return;
    }

    const givenDomain = userEmail.length !== 0 && userEmail.split("@")[1];
    if (!allDomains.find((r: any) => r.domain === givenDomain)) {
      setError(
        "This portal is for Ericsson employees and partners only. If you are none, please leave immediately!"
      );
      setShowError(true);
      return;
    }

    if (regexForEmail.test(userEmail)) {
      setError("Special characters except @ and - are not allowed for email");
      setShowError(true);
      return;
    }

    reSubmitExpiredLinkFault();
  };
  const renderErrorMessage = () => {
    return (
      <Alert variant="danger" className="error-alert">
        {error}
      </Alert>
    );
  };
  return (
    <>
      <div className="app-container">
        <img className="frImage" src={faultReportingImg} alt="fr" />
        <p>
          {isLoading && (
            <Loading
              style={{ textAlign: "center" }}
              className="verify-loading-icon"
            />
          )}
        </p>
        <p className="frTextOption">{statusMessage}</p>
        {linkExpiredStatus && (
          <div className="frTextOption">
            <p>
              Email{" "}
              <input
                className="verify-email-text"
                onChange={(event) => setUserEmail(event.target.value.trim())}
                type="text"
                placeholder="Enter your email"
              />{" "}
              <Button
                onClick={() => {
                  validate();
                }}
              >
                Submit
              </Button>
            </p>
          </div>
        )}
      </div>
      {showError && renderErrorMessage()}
    </>
  );
};
