import { getBaseUrl } from "./../utils/Constant";
import { Buffer } from "buffer";
import axios from "axios";
import { Fault, FaultVerificationType } from "../models/Issue";
export function getAxiosHeader() {
  let headers = {
    Authorization: `Basic ${Buffer.from(
      `${getBaseUrl().api_uname}:${getBaseUrl().api_passwd}`
    ).toString("base64")}`,
  };
  return headers;
}

export const generateCodeForDevicePairingWithEmailApi = async (
  email: string,
  deviceId: string
) => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseUrl().api_base_url}`,
    headers: getAxiosHeader(),
  });
  let data = {
    userEmail: email,
    deviceID: deviceId,
  };

  try {
    const response = await axiosInstance.post(`/one-time/newCode`, data);
    console.log(response);
    if (response.status === 200)
      return {
        result: response.data.isGenerated,
        is_error: false,
      };
    else
      return {
        result: null,
        is_error: true,
      };
  } catch (error: any) {
    console.log(error);
    return {
      result: error?.response?.message || "Something Went Wrong",
      is_error: true,
    };
  }
};

export const captureFaultAndSendVerificationLink = async (
  faultDetails: Fault
) => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseUrl().api_base_url}`,
    headers: getAxiosHeader(),
  });

  try {
    const response = await axiosInstance.post(
      `/one-time/captureFaultAndSendVerificationLink`,
      faultDetails
    );

    return {
      result: response.data.isGenerated,
      is_error: false,
    };
  } catch (error: any) {
    return {
      result: error?.response?.message || "Something Went Wrong",
      is_error: true,
    };
  }
};

export const validateCodeForDevicePairingWithEmailApi = async (
  email: string,
  codeEntered: string,
  deviceId: string
) => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseUrl().api_base_url}`,
    headers: getAxiosHeader(),
  });
  let data = {
    userEmail: email,
    codeEntered: codeEntered,
    deviceID: deviceId,
  };

  try {
    const response = await axiosInstance.post(`/one-time/validateCode`, data);
    if (response.status === 200)
      return {
        result: response.data,
      };
    else
      return {
        result: null,
      };
  } catch (error: any) {
    console.log(error);
    return {
      result: error?.response?.message || "Something Went Wrong",
    };
  }
};

export const validateLinkAndSubmitFault = async (
  faultDetails: FaultVerificationType
) => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseUrl().api_base_url}`,
    headers: getAxiosHeader(),
  });

  try {
    const response = await axiosInstance.post(
      `/one-time/validateLinkAndSubmitFault`,
      faultDetails
    );
    return {
      result: response.data,
      is_error: false,
    };
  } catch (error: any) {
    return {
      result: error?.response?.message || "Something Went Wrong",
      is_error: true,
    };
  }
};

export const getAllEmailDomains = async () => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseUrl().api_base_url}`,
    headers: getAxiosHeader(),
  });

  try {
    const response = await axiosInstance.get(`/manage-email-domain/fetch-all`);
    return {
      result: response.data.domains,
      is_error: false,
    };
  } catch (error: any) {
    return {
      result: error?.response?.message || "Something Went Wrong",
      is_error: true,
    };
  }
};

export const reSubmitFault = async (faultDetails: FaultVerificationType) => {
  const axiosInstance = axios.create({
    baseURL: `${getBaseUrl().api_base_url}`,
    headers: getAxiosHeader(),
  });

  try {
    const response = await axiosInstance.post(
      `/one-time/reSubmitFault`,
      faultDetails
    );
    return {
      result: response.data,
      is_error: false,
    };
  } catch (error: any) {
    return {
      result: error?.response?.message || "Something Went Wrong",
      is_error: true,
    };
  }
};
