import React from 'react';
import './not-found-page.scss';
import Alert from 'react-bootstrap/Alert';

const NotFound: React.FC = (): JSX.Element => {
    return (
        <div className="not-found">
            <Alert variant="danger"
                className="custom-alert">
                That's a 404 - The requested item was not found :(
            </Alert>
            Please close this window
        </div>
    );
}

export default NotFound;