import React, { useState } from "react";
import { Space, Floor } from "../../models/Asset";
import "./equipment_box.scss";
import { ProblemCode } from "../../models/Equipment";
import Header from "../header/header";
import EquipmentCard from "./equipment_eds_card";
import { Dialog } from "@dsi/react-eds";

type Props = {
  space: Space | Floor | undefined;
  callback: (eq: ProblemCode) => void;
  isExtView: Boolean;
  isDeskReportFlag: Boolean;
  source: string;
  locationCode: string;
  mail: string;
  theme: string;
  setAckBoxClosed: (status: boolean) => void;
};

const EquipmentBox = (props: Props): JSX.Element => {
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [formMessage, setFormMessage] = useState<any>("");

  const next = (problemCode: ProblemCode) => {
    props.callback(problemCode);
  };

  var allLinks = document.links;

  // Bind the event handler to each link individually
  for (var i = 0, n = allLinks.length; i < n; i++) {
    allLinks[i].onclick = function () {
      console.log("im clicked");
      setFormMessage("Please send the email and thanks for the feedback!!!");
      setShowValidationDialog(true);
    };
  }
  return (
    <>
      <div className="dialog-info">
        <Dialog
          show={showValidationDialog}
          title="Info"
          closeButtonLabel="Ok"
          onClose={() => {
            setShowValidationDialog(false);
          }}
        >
          {formMessage}
        </Dialog>
      </div>
      <Header {...props} isEquipmentBox={true} />
      <div>
        {props.space !== undefined &&
        props.space.problemCodeList !== undefined &&
        props.space.problemCodeList.length > 0 ? (
          <div className="equiment_card_margin">
            <div className="equipment_container">
              {props.space.problemCodeList.map((item, index) => {
                return (
                  <EquipmentCard
                    key={`eqp-card-${index}`}
                    item={item}
                    index={index}
                    _onClickEquipment={() => {
                      next(item);
                      props.setAckBoxClosed(false);
                    }}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="equiment_card_margin">
            No problem codes enabled for Fault Reporting
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default EquipmentBox;
