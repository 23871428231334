import React from "react";
import { Button } from "@dsi/react-eds";
import "./fault_reporting_options.scss";
import {
  openExternalUrl,
  getBaseUrl,
  FAULT_REPORT_ROUTE,
} from "../../utils/Constant";

import faultReportingImg from "../../images/fault_report_hero.png";
type Props = {
  setContinueHere: (status: boolean) => void;
  locationCode: string;
  space: string;
};

export const FaulReportingOptions = (props: Props) => {
  const faultReportUrlPart =
    props.space.length === 8
      ? `${props.space.toUpperCase()}/true`
      : `${props.space.toUpperCase()}/false`;
  return (
    <div className="app-container">
      <img className="frImage" src={faultReportingImg} alt="fr" />
      <p className="frTextOption">
        Thank you for using the Fault Report solution, to send the fault report
        we will first need to validate your email address.
      </p>
      <p className="frText3Option">
        If you are logged in and continue in My Workplace App you are already
        validated, therefore this is the recommended option.
      </p>      
      <p>
        <Button
          className="fr-options-button"
          onClick={() => props.setContinueHere(true)}
        >
          Continue here (will require validation)
        </Button>
      </p>
      <p>
        <Button
          primary
          className="fr-options-button2"
          onClick={() =>
            openExternalUrl(
              `${
                getBaseUrl().mwp_url
              }#/${props?.locationCode!.toLowerCase()}/${FAULT_REPORT_ROUTE}/report/${faultReportUrlPart}`
            )
          }
        >
          Go to My Workplace App (Recommended)
          <i className="icon icon-launch marginIcon"></i>
        </Button>
      </p>
    </div>
  );
};
