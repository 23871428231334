import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from "./components/not-found-page/not-found-page";
import { ThemeProvider } from "@dsi/react-eds";
import "./index.scss";
import "@eds/vanilla/eds.min.css";

const routes = (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={App} exact={true} />
      <Route path="/web" component={App} exact={true} />
      <Route path="/web/fault-report" component={App} exact={true} />
      <Route path="/web/fault-report/:id" component={App} exact={true} />
      <Route path="/web/workspace-support" component={App} exact={true} />
      <Route path="/web/workspace-support/:id" component={App} exact={true} />
      <Route path="web/fault-report/verify" component={App} exact={true} />
      <Route component={NotFound} exact={true} />
    </Switch>
  </BrowserRouter>
);

ReactDOM.render(
  <ThemeProvider>
    <React.StrictMode>{routes}</React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
