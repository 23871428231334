import { useState } from "react";
import icon_other from "../../icons/Others.png";
import icon_audio from "../../icons/audio.png";
import icon_cable from "../../icons/cable.png";
import icon_cleaning from "../../icons/cleaning.png";
import icon_heating from "../../icons/heating.png";
import icon_interior from "../../icons/interior.png";
import icon_keyboard from "../../icons/keyboard.svg";
import icon_lcd from "../../icons/lcd.png";
import icon_lights from "../../icons/lights.png";
import icon_video from "../../icons/video.png";
import icon_wifi from "../../icons/wifi.png";
import {
  FEATURE_FLAGS,
  calculateRemainingTime,
  renderExtStatus,
  renderVendorName,
} from "../../utils/Constant";
import "./equipment_box.scss";

import { Card, Tag } from "@dsi/react-eds";
function EquipmentCard(props: any) {
  function _callImage(item: any) {
    switch (item) {
      case "Audio":
        return icon_audio;
      case "Video":
        return icon_video;
      case "Projector":
        return icon_video;
      case "Interior":
        return icon_interior;
      case "Display":
        return icon_lcd;
      case "Monitor":
        return icon_lcd;
      case "Room Panel":
        return icon_lcd;
      case "Heating and Cooling":
        return icon_heating;
      case "Lights":
        return icon_lights;
      case "Cleaning":
        return icon_cleaning;
      case "Cables":
        return icon_cable;
      case "WiFi":
        return icon_wifi;
      case "Peripherals (keyboard, mouse)":
        return icon_keyboard;
      default:
        return icon_other;
    }
  }
  const ReadMore = ({ children }: any) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState<boolean>(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore && text?.length >= 50 ? text.slice(0, 55) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {text?.length >= 50 && (isReadMore ? "...read more" : " show less")}
        </span>
      </p>
    );
  };

  return (
    <div className="text-sm">
      <Card title="">
        <div>
          {props.item.extStatus !== "RESOLVED" &&
          (props.item.markedForFaultReporting ||
            props.item.faultDescription) ? (
            <div className="equipment_card_faulty">
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() =>
                  !props.item.markedForFaultReporting &&
                  props.item.faultDescription &&
                  props._onClickEquipment(props.item)
                }
              >
                <img
                  className={`equipment_img_faulty ${
                    props.item.markedForFaultReporting &&
                    "marked_ForFault_Reporting"
                  }`}
                  src={_callImage(props.item.problemCode)}
                  alt=""
                />
                <p className="equiment_name">{props.item.problemCode} </p>

                {props.item.markedForFaultReporting && (
                  <span className="equiment_faulty_icon">
                    <i className={"icon icon-triangle-warning"} />
                  </span>
                )}
                {!props.item.markedForFaultReporting &&
                  props.item.faultDescription && (
                    <div className="equiment_reported_icon">
                      <i className="icon icon-arrow-right" />
                    </div>
                  )}
              </div>

              <div className="issue_text">
                {props.item.markedForFaultReporting && (
                  <div>
                    <Tag icon="lock-locked" iconClassName="text-xl">
                      {`Report again in ${calculateRemainingTime(
                        props.item?.reportingTimestamp
                      )}`}
                    </Tag>
                    <br />
                    <Tag icon="info" iconClassName="text-xl">
                      This problem can be reported once every 24h
                    </Tag>
                  </div>
                )}
                {!props.item.markedForFaultReporting &&
                  props.item.extStatus === "SYS_REPORTED" && (
                    <div>
                      <Tag icon="info" iconClassName="text-xl">
                        This problem is reported recently but you can report
                        another problem
                      </Tag>
                    </div>
                  )}
                {!props.item.markedForFaultReporting &&
                  props.item.extStatus === "SYS_CAPTURED" && (
                    <div>
                      <Tag icon="info" iconClassName="text-xl">
                        This problem is submitted by someone, pending
                        verification
                      </Tag>
                    </div>
                  )}
                {props.item?.vendorName &&
                  FEATURE_FLAGS.displayExtSatusForFaults && (
                    <div>
                      Reported to{" "}
                      <Tag
                        iconClassName="text-xl"
                        color={
                          props.item.markedForFaultReporting ? "red" : "yellow"
                        }
                      >
                        {`${renderVendorName(props.item?.vendorName)}`}
                      </Tag>
                    </div>
                  )}
                {props.item?.extStatus &&
                  renderExtStatus(props.item?.extStatus) &&
                  FEATURE_FLAGS.displayExtSatusForFaults && (
                    <div>
                      Last known status is{" "}
                      <Tag
                        iconClassName="text-xl"
                        color={
                          props.item.markedForFaultReporting ? "red" : "yellow"
                        }
                      >
                        {`${renderExtStatus(props.item?.extStatus)
                          .toLowerCase()
                          .replace("_", " ")}`}
                      </Tag>
                    </div>
                  )}
                <i className="icon icon-avatar text-lg"></i>{" "}
                <i className="icon icon-feedback text-sm user-fault-description-icon"></i>
                &nbsp;
                <ReadMore>{props.item.faultDescription}</ReadMore>
              </div>
            </div>
          ) : (
            <div className="equipment_card">
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => props._onClickEquipment(props.item)}
              >
                <img
                  className="equipment_img "
                  src={_callImage(props.item.problemCode)}
                  alt=""
                />
                <p className="equiment_name">{props.item.problemCode}</p>
                <div className="equiment_not_faulty_icon">
                  <i className="icon icon-arrow-right" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export default EquipmentCard;
