import React, { useEffect, useState } from "react";
import {
  SystemBar,
  SystemBarComponent,
  AppContainer,
  AppBody,
} from "@dsi/react-eds";
import WorkspaceSupport from "./components/workspacesupport";
import "./App.scss";
import SysPanel from "./components/SysPanel/SysPanel";
import AppThemeChanger from "./ThemeChanger/ThemeChanger";
import { FaulReportingOptions } from "./components/fault-reporting-options/fault_reporting_options";
import { renderEmpty } from "../src/utils/components";
import url from "url";
import { FEATURE_FLAGS, DEVICE_EMAIL_PAIR_ATTRIBUTE } from "./utils/Constant";
import { Verify } from "./components/verify/verify";

const App: React.FC = (props: any): JSX.Element => {
  const privacyNotice = `<p style="text-align: center;"><strong>Privacy Notice&nbsp;</strong></p>
        <p style="text-align: left;"><strong>Fault Report (Applicable as we are collecting your email ID)</strong> <br /><br />The scope of this notice is related to Ericsson processing personal data in the role of employer and the usage of Fault report application <br /><br /><strong>Who is collecting personal data?</strong><br />The personal data is being collected by the Controller, Ericsson AB, 16480 Stockholm, Sweden. For more information, rectification, erasure, portability, or complaints to supervisory authorities about the processing of personal data please contact smartoffice.support@ericsson.com or an Ericsson Data Protection Officer. <br /><br /><strong>Why is personal data collected and processed?</strong><br />Your personal data, email ID is collected when you voluntarily add it in the form, the email address is then sent to the support organization to contact you if question related to the issue reported and/or notify you when the issue have been resolved. <br /><br /><strong>What type of personal data is collected or processed?</strong><br />Personal data being processed, email address, IP-address. <br /><br /><strong>How long are the information retained?</strong><br />The email ID are removed automatically when the issue has been resolved or after a duration of 180 days. <br /><br /><strong>Where does the personal data that&rsquo;s being processed come from?</strong><br />The personal data originate from data subject, the user.</p>`;

  const [userSettings, setUserSettings] = useState<boolean>(false);
  const [showPrivacyNotice, setShowPrivacyNotice] = useState(false);
  const [continueHere, setContinueHere] = useState<boolean>(false);

  const toggleShowPrivacyNotice = () =>
    setShowPrivacyNotice(!showPrivacyNotice);
  const deviceEmailPairAttrJson = localStorage.getItem(
    DEVICE_EMAIL_PAIR_ATTRIBUTE
  );

  useEffect(() => {
    const currentEpochTime = Math.round(new Date().getTime() / 1000);

    if (!deviceEmailPairAttrJson) {
      setContinueHere(false);
    } else {
      const { ttlUtc } = JSON.parse(deviceEmailPairAttrJson);
      if (ttlUtc < currentEpochTime) {
        localStorage.removeItem(DEVICE_EMAIL_PAIR_ATTRIBUTE);
        setContinueHere(false);
      } else if (ttlUtc > currentEpochTime) {
        setContinueHere(true);
      } else {
        setContinueHere(false);
      }
    }
  }, [deviceEmailPairAttrJson]);

  const renderPrivacyNotice = () => {
    return (
      <>
        <div className="privacy-notice ">
          <div className="back-icon">
            <i
              className="icon icon-arrow-left"
              onClick={toggleShowPrivacyNotice}
            ></i>
          </div>
          <div
            style={{ fontSize: "1.25em", marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{ __html: privacyNotice }}
          ></div>
        </div>
      </>
    );
  };

  let _theme = "light";
  let _id = "";
  let _mail = "";
  let _source = "";
  let _locationCode = "";
  let _problemReported = "";
  let _isFloorSearchSelected = "";

  console.log("app props ", props);

  const href = window.location.href || undefined;
  const parsedURL = href && url.parse(href, true).query;
  console.log("parsedURL", parsedURL);
  if (parsedURL) {
    if (typeof parsedURL.theme === "string") {
      _theme = parsedURL.theme.trim() || _theme;
    }
    if (typeof parsedURL.id === "string") {
      _id = parsedURL.id.trim() || _id;
    }
    if (typeof parsedURL.mail === "string") {
      _mail = parsedURL.mail.trim() || _mail;
    }
    if (typeof parsedURL.source === "string") {
      _source = parsedURL.source.trim() || _source;
    }
    if (typeof parsedURL.locationCode === "string") {
      _locationCode =
        parsedURL.locationCode.toUpperCase().trim() || _locationCode;
    }
    if (typeof parsedURL.problemReported === "string") {
      _problemReported = parsedURL.problemReported.trim() || _problemReported;
    }
    if (typeof parsedURL.isFloorSearchSelected === "string") {
      _isFloorSearchSelected =
        parsedURL.isFloorSearchSelected.trim() || _isFloorSearchSelected;
    }
  }

  console.log({
    _theme,
    _id,
    _mail,
    _source,
    _locationCode,
    _problemReported,
    _isFloorSearchSelected,
  });
  const newProps = {
    ...props,
    theme: _theme,
    id: _id,
    mail: _mail,
    source: _source,
    locationCode: _locationCode,
    problemReported: _problemReported,
    isFloorSearchSelected: _isFloorSearchSelected,
  };
  console.log("newProps", newProps);

  function toggleSysPanel(value: any) {
    setUserSettings(!userSettings);
  }

  const renderApp = () => {
    return (
      <>
        <SystemBar product="Fault Report" acronym="Fault Report">
          <SystemBarComponent onClick={() => toggleSysPanel("userSettings")}>
            <div className="row" style={{ margin: "0 .2em 0 .2em" }}>
              <span>
                <i className="icon icon-settings"></i>
              </span>
            </div>
          </SystemBarComponent>
        </SystemBar>
        <AppContainer
          hideNavigation={true}
          showSystemPanel={userSettings}
          systemPanels={[
            <SysPanel
              key="userPanel"
              hidden={!userSettings}
              onClose={() => toggleSysPanel("userSettings")}
            />,
          ]}
        >
          <AppBody navigation={null}>
            <div className="App">
              {FEATURE_FLAGS.enableNewPlandingPage &&
                props.location.pathname.split("/").length >= 4 &&
                (props.location.pathname.toLowerCase().includes("verify") ? (
                  <Verify />
                ) : (
                  !continueHere && (
                    <FaulReportingOptions
                      setContinueHere={setContinueHere}
                      locationCode={props.location.pathname
                        .split("/")[3]
                        .slice(0, 4)}
                      space={props.location.pathname.split("/")[3]}
                    />
                  )
                ))}

              {FEATURE_FLAGS.enableNewPlandingPage &&
                !continueHere &&
                props.location.pathname.split("/").length < 4 &&
                renderEmpty()}

              {FEATURE_FLAGS.enableNewPlandingPage && continueHere && (
                <WorkspaceSupport {...newProps} />
              )}
              {!FEATURE_FLAGS.enableNewPlandingPage && (
                <WorkspaceSupport {...newProps} />
              )}
            </div>
            <div className="footer">
              <span onClick={toggleShowPrivacyNotice}>
                <u>Privacy Policy</u>
              </span>
            </div>
          </AppBody>
        </AppContainer>
      </>
    );
  };

  return (
    <>
      {_source === "workplace-app" ? (
        <>
          <AppContainer hideNavigation={true} showSystemPanel={false}>
            <AppBody navigation={null}>
              <div className="App">
                <WorkspaceSupport {...newProps} />
                <AppThemeChanger _theme={_theme} />
              </div>
            </AppBody>
          </AppContainer>
        </>
      ) : (
        <>{showPrivacyNotice ? renderPrivacyNotice() : renderApp()}</>
      )}
    </>
  );
};

export default App;
