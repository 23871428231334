import "./SysPanel.scss";
import React, { useEffect } from "react";
import classNames from "classnames";
import packageJson from "../../../package.json";
import { Switch, useTheme } from "@dsi/react-eds";
import {
    local_theme,
} from "../../utils/Constant.js";

export interface PanelProps {
    hidden: boolean,
    onClose: () => void
}

export default function SysPanel({
    hidden,
    onClose,
}: PanelProps) {
    const [theme, setTheme] = useTheme()
    const ref = React.useRef<HTMLDivElement>(null);
    const switchRef = React.useRef<HTMLInputElement>(null);

    const classes = classNames("settings", { hidden });
    
    useEffect(() => {
        if (localStorage.getItem(local_theme) === "dark") {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }
    );



    return (
        <div ref={ref} className={classes}>
            <div className="syspanel-top" style={{ overflow: "scroll" }}>
                <span className="syspanel-title" />
                <i className="syspanel-close icon icon-cross" onClick={onClose} />
            </div>
            <div className="row" >
                <div className="column sm-12 container" style={{ paddingTop: "0" }}>
                    <div className="content">
                        <div className="title" style={{ marginTop: "-20px" }}>My settings</div>

                        <div className="item" style={{ marginTop: "1.5em" }}>
                            <div className="left">Switch theme</div>
                            <div className="right">
                                <Switch
                                    ref={switchRef}
                                    checked={theme === "light"}
                                    enabledLabel="Light"
                                    disabledLabel="Dark"
                                    onChange={() => {
                                        if (null !== switchRef.current) {
                                            if (switchRef.current.checked) {
                                                localStorage.setItem(local_theme, "light");
                                                setTheme("light");
                                            } else {
                                                localStorage.setItem(local_theme, "dark");
                                                setTheme("dark");
                                            }
                                        }
                                        onClose();
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bottom" style={{ backgroundColor: "#0C0C0C" }}>
                        <div>
                            <span style={{ color: "#0C0C0C" }}>{packageJson.version}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
SysPanel.defaultProps = {
    hidden: false,
    userName: "User Name",
}