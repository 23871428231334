import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { getBaseUrl } from "./../../utils/Constant";
const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getBaseUrl().instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      enableAutoRouteTracking: true,
    },
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export { reactPlugin, appInsights };
