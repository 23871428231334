import {
  COMPUTER_CLIENT_MODEL,
  isIphone,
  isMobile,
  PHONE_CLIENT_MODEL,
} from "../../utils/Constant";
import { appInsights } from "./AppInsights";

export function aiTrackEvent(pageName, locationCode, emailHash) {
  appInsights.trackPageView(pageName);
  appInsights.trackEvent({
    name: pageName,
    properties: {
      location_code: locationCode,
      widget_type: "global",
      user_id: emailHash,
      device:
        isMobile.any() || isIphone ? PHONE_CLIENT_MODEL : COMPUTER_CLIENT_MODEL,
    },
  });
}
