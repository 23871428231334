import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Alert from "react-bootstrap/Alert";
import imageCompression from "browser-image-compression";
import "./report_box.scss";
import {
  captureFaultAndSendVerificationLink,
  getAllEmailDomains,
} from "../../api/api";
import { Space, Floor } from "../../models/Asset";
import { ProblemCode } from "../../models/Equipment";
import { Fault } from "../../models/Issue";
import icon_audio from "../../icons/audio.png";
import icon_video from "../../icons/video.png";
import icon_lcd from "../../icons/lcd.png";
import icon_interior from "../../icons/interior.png";
import icon_lights from "../../icons/lights.png";
import icon_cable from "../../icons/cable.png";
import icon_cleaning from "../../icons/cleaning.png";
import icon_heating from "../../icons/heating.png";
import icon_other from "../../icons/Others.png";
import icon_wifi from "../../icons/wifi.png";
import {
  getBaseUrl,
  simpleHash,
  maxFaultDescriptionLength,
  maxSizeMB,
  DEVICE_EMAIL_PAIR_ATTRIBUTE,
  FEATURE_FLAGS,
} from "../../utils/Constant";
import { aiTrackEvent } from "../monitor/AppInsightManager";
import icon_keyboard from "../../icons/keyboard.svg";

// import imageCompression from 'browser-image-compression';
import Header from "../header/header";
import {
  Card,
  Button,
  FileUpload,
  Input,
  Dialog,
  Loading,
  Checkbox,
} from "@dsi/react-eds";
import { Buffer } from "buffer";
import { maxSizeInBytes } from "../../utils/Constant";

type Props = {
  space: Space | Floor | undefined;
  problemCode: ProblemCode | undefined;
  callback: (state: boolean) => void;
  back: () => void;
  isDeskReportFlag: Boolean;
  source: string;
  locationCode: string;
  selectedLocationCode: string;
  mail: string;
  theme: string;
  reportButtonText: string;
  setReportButtonText: (reportButtonText: string) => void;
  isFloorSearchSelected: boolean;
};

const ReportBox = (props: Props): JSX.Element => {
  const [error, setError] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [deskNum, setDeskNum] = useState<string>("");
  const [reportButtonDisabled, setReportButtonDisabled] =
    useState<boolean>(false);
  const [pictureBase64, setPictureBase64] = useState<string>();
  const [fileName, setFileName] = useState<string>("");
  const [allDomains, setAllDomains] = useState<any>([]);
  const [trackEvent, setTrackEvent] = useState<any>(null);
  const [showPairDeviceConfirmation, setShowPairDeviceConfirmation] =
    useState<boolean>(false);
  const [sendLinkError, setSendLinkError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailFromStorage, setEmailFromStorage] = useState<string>("");
  const [isConfidential, setIsConfidential] = useState<boolean>(false);

  const fileInput = useRef(null);

  function _callImage(item: any) {
    switch (item) {
      case "Audio":
        return icon_audio;
      case "Video":
        return icon_video;
      case "Projector":
        return icon_video;
      case "Interior":
        return icon_interior;
      case "Display":
        return icon_lcd;
      case "Monitor":
        return icon_lcd;
      case "Room Panel":
        return icon_lcd;
      case "Heating and Cooling":
        return icon_heating;
      case "Lights":
        return icon_lights;
      case "Cleaning":
        return icon_cleaning;
      case "Cables":
        return icon_cable;
      case "WiFi":
        return icon_wifi;
      case "Peripherals (keyboard, mouse)":
        return icon_keyboard;
      default:
        return icon_other;
    }
  }

  useEffect(() => {
    getDomains();
    async function getDomains() {
      if (props.mail) {
        setEmail(props.mail.toLowerCase());
      }
      const getDomains = await getAllEmailDomains();
      getDomains && setAllDomains(getDomains.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allDomains) {
      const deviceEmailPairAttrJson = localStorage.getItem(
        DEVICE_EMAIL_PAIR_ATTRIBUTE
      );
      if (deviceEmailPairAttrJson) {
        const { emailId } = JSON.parse(deviceEmailPairAttrJson);
        setEmailFromStorage(emailId);
      }
    }
  }, [allDomains]);

  useEffect(() => {
    if (trackEvent) {
      aiTrackEvent(
        "Fault Report (standAlone)",
        props.selectedLocationCode,
        trackEvent
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEvent]);

  const report = async () => {
    const regexForEmail = /[!#$%^&*()+=[\]{};':"\\|,<>/? ]/g;
    if (email.length === 0 && emailFromStorage.length === 0) {
      setError(
        "Email is mandatory, enter your Ericsson email or Ericsson partner email"
      );
      setShowError(true);
      return;
    }

    const givenDomain =
      email.length !== 0 ? email.split("@")[1] : emailFromStorage.split("@")[1];
    if (!allDomains.find((r: any) => r.domain === givenDomain)) {
      setError(
        "This portal is for Ericsson employees and partners only. If you are none, please leave immediately!"
      );
      setShowError(true);
      return;
    }

    if (regexForEmail.test(email.length !== 0 ? email : emailFromStorage)) {
      setError("Special characters except @ and - are not allowed for email");
      setShowError(true);
      return;
    }

    if (comments.length === 0) {
      setError("Please describe the problem in a few words");
      setShowError(true);
      return;
    }
    if (comments.length > maxFaultDescriptionLength) {
      setError(
        `Please describe the problem in less than ${maxFaultDescriptionLength} characters`
      );
      setShowError(true);
      return;
    }

    const isDevicePairedWithEmailCurrently =
      props.source !== "" || !FEATURE_FLAGS.enableDevicePairing
        ? true
        : await checkForDeviceEmailPairing();

    isDevicePairedWithEmailCurrently && reportFault();
  };

  const reportFault = async () => {
    props.setReportButtonText("Reporting... Please wait");
    setReportButtonDisabled(true);
    setTrackEvent(simpleHash(email));
    let issue = new Fault();
    issue.problemCode =
      (props.problemCode && props.problemCode.problemCode) || "undefined";
    issue.space_ = (props.space && props.space.spaceId) || "undefined";
    issue.imageBase64 = pictureBase64;
    issue.reportedBy = email.length !== 0 ? email : emailFromStorage;
    issue.isConfidentialFaultDescription = isConfidential;
    if (props.isDeskReportFlag) {
      console.log(`Desk No:${deskNum}, Comments: ${comments}`);
      issue.faultDescription = `Desk No:${deskNum}, Comments: ${comments}`;
    } else {
      console.log(comments);
      issue.faultDescription = comments;
    }

    window.localStorage.setItem(
      "ericsson.workspacesupport.reportee.email.private",
      email
    );

    fetch(`${getBaseUrl().api_base_url}/report`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Basic ${Buffer.from(
          `${getBaseUrl().api_uname}:${getBaseUrl().api_passwd}`
        ).toString("base64")}`,
      }),
      body: JSON.stringify(issue),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 413) {
            setError("Image size is too big");
          } else {
            setError("An error occurred... Try again");
          }
          setShowError(true);
          props.setReportButtonText("Try reporting this problem again");
          setReportButtonDisabled(false);
          props.callback(false);
          return;
        }
        return response.json();
      })
      .then((data) => {
        data && console.log(data);
        data && props.callback(true);
        data && props.setReportButtonText("Problem reported");
      })
      .catch((e) => {
        console.log(e);
        props.setReportButtonText("error occurred");
      });
  };

  const setInputForBrowseFile = async (event: any) => {
    if (event.target.files && event.target.files.length) {
      setFileName(event.target.files[0].name);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const base64 = await onCameraData(event.target.files[0]);
    }
  };

  const onCameraData = async (file: any) => {
    try {
      let imageFile = file;
      if (imageFile.size > maxSizeInBytes) {
        imageFile = await imageCompression(file, {
          maxSizeMB: maxSizeMB,
        });
      }

      let reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = function () {
        const base64data = reader.result?.toString();
        console.log("The base64 string data is", base64data);
        setPictureBase64(base64data);
      };
    } catch (error) {
      console.log(error);
    }
  };

  const renderErrorMessage = () => {
    return (
      <Alert variant="danger" className="error-alert">
        {error}
      </Alert>
    );
  };

  const checkForDeviceEmailPairing = async () => {
    const currentEpochTime = Math.round(new Date().getTime() / 1000);

    const deviceEmailPairAttrJson = localStorage.getItem(
      DEVICE_EMAIL_PAIR_ATTRIBUTE
    );

    if (!deviceEmailPairAttrJson) {
      localStorage.removeItem(DEVICE_EMAIL_PAIR_ATTRIBUTE);
      setShowPairDeviceConfirmation(true);
    } else {
      const { emailId, ttlUtc } = JSON.parse(deviceEmailPairAttrJson);
      if (
        ttlUtc < currentEpochTime ||
        (email.length !== 0 ? email !== emailId : emailFromStorage !== emailId)
      ) {
        localStorage.removeItem(DEVICE_EMAIL_PAIR_ATTRIBUTE);
        setShowPairDeviceConfirmation(true);
      } else if (
        ttlUtc > currentEpochTime &&
        (email.length !== 0 ? email === emailId : emailFromStorage === emailId)
      ) {
        return true;
      }
    }
  };

  const CatchFaultAndSendVerificationLink = async () => {
    try {
      setIsLoading(true);
      let deviceId = uuidv4().toString();

      setTrackEvent(simpleHash(email));
      let issue = new Fault();
      issue.problemCode =
        (props.problemCode && props.problemCode.problemCode) || "undefined";
      issue.space_ = (props.space && props.space.spaceId) || "undefined";
      issue.imageBase64 = pictureBase64;
      issue.reportedBy = email.length !== 0 ? email : emailFromStorage;
      issue.isConfidentialFaultDescription = isConfidential;
      if (props.isDeskReportFlag) {
        console.log(`Desk No:${deskNum}, Comments: ${comments}`);
        issue.faultDescription = `Desk No:${deskNum}, Comments: ${comments}`;
      } else {
        console.log(comments);
        issue.faultDescription = comments;
      }
      issue.deviceID = deviceId;

      const res = await captureFaultAndSendVerificationLink(issue);

      if (!res.is_error && res.result) {
        props.callback(true);
        props.setReportButtonText("Link Sent");
        setShowPairDeviceConfirmation(false);
        setShowError(false);
      } else {
        setSendLinkError("Error sending email.");
        props.setReportButtonText("Error sending email.");
      }
    } catch (err: any) {
      setSendLinkError("Error sending email.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderConfirmDevicePairingBox = () => {
    return (
      <Dialog
        title="Email validation"
        className="one-time-code-dialog"
        show={showPairDeviceConfirmation}
        onClose={() => {
          setShowPairDeviceConfirmation(false);
          setSendLinkError("");
        }}
        bottom={
          <Button
            primary
            className="one-time-code-button"
            onClick={() => {
              CatchFaultAndSendVerificationLink();
            }}
          >
            Send Link (Valid for 1 hour)
          </Button>
        }
      >
        {isLoading && <Loading className="loading-one-time-code" />}
        {!isLoading && (
          <>
            {" "}
            <p>
              Before we can complete your fault report we need you to validate
              your email address.
            </p>
            <p>
              Check your email and click the link to complete, your device will
              remain validated for the next 30 days.
            </p>
          </>
        )}

        {sendLinkError.length !== 0 && (
          <div className="mt-1 error-message">{sendLinkError}</div>
        )}
      </Dialog>
    );
  };

  return (
    <>
      <Header {...props} />
      <div className="card_container">
        <Card title={``}>
          <div
            className="row"
            style={{ margin: "0", marginLeft: "1.2em", alignItems: "center" }}
          >
            <img
              className="image_report"
              src={_callImage(
                props.problemCode && props.problemCode.problemCode
              )}
              alt=""
            />
            &nbsp;&nbsp;
            <h5 style={{ margin: "0" }}>
              {props.problemCode && props.problemCode.problemCode}
            </h5>
          </div>
          <br />
          {props.isDeskReportFlag ? (
            <>
              <p className="report_text">Desk Number</p>
              <input
                style={{
                  width: "70vw",
                  height: "3em",
                }}
                onChange={(event) =>
                  setDeskNum(event.target.value.toUpperCase().trim())
                }
                type="text"
                className="custom-desk"
                value={deskNum}
                placeholder="Enter your desk number"
              />
              <p className="report_text" style={{ marginTop: "1em" }}>
                Write your issue here
              </p>
              <p className="count_text mb-2">
                {comments.length} / {maxFaultDescriptionLength}
              </p>
              <textarea
                maxLength={maxFaultDescriptionLength}
                className="text-sm"
                placeholder="Write what's wrong at this desk. This helps our awesome support teams to understand the problem correctly*"
                onChange={(event) => setComments(event.target.value)}
                style={{
                  margin: "0",
                  width: "70vw",
                  height: "15vh",
                  border: "solid 0.05em",
                }}
              ></textarea>
            </>
          ) : (
            <>
              <p className="count_text mb-2">
                {comments.length} / {maxFaultDescriptionLength}
              </p>
              <textarea
                maxLength={maxFaultDescriptionLength}
                className="text-sm"
                placeholder="Write what's wrong with this equipment. This helps our awesome support teams to understand the problem correctly*"
                onChange={(event) => setComments(event.target.value)}
                style={{
                  margin: "0",
                  width: "70vw",
                  height: "15vh",
                  border: "solid 0.05em",
                }}
              ></textarea>
            </>
          )}
          <Checkbox
            id="keep-confidential"
            onChange={() => setIsConfidential(!isConfidential)}
          >
            Don't show my comments to other users
          </Checkbox>
          <div style={{ marginTop: "2em" }}>
            <FileUpload
              id="myFile"
              icon="camera-photo"
              ref={fileInput}
              type="file"
              accept=".jpg,.png"
              onChange={(event) => {
                setInputForBrowseFile(event);
              }}
            >
              Take or attach picture
            </FileUpload>
          </div>
          {fileName}
          <br />
          <h6 className="mt-0">
            Please adhere to local photography requirements
          </h6>
          <h6
            style={{
              margin: "0",
              marginTop: "1%",
            }}
          >
            {props?.source !== ""
              ? "We will reach you on this email if required"
              : " How do we reach you?"}
          </h6>
          <Input
            id="fr-mail-id"
            disabled={props?.source !== "" ? true : false}
            onChange={(event) => setEmail(event.target.value.toLowerCase())}
            type="text"
            className="custom-email text-lg"
            value={email.length !== 0 ? email : emailFromStorage}
            placeholder="Enter email here..."
          />
          <div style={{ marginTop: "1em", alignItems: `center` }}>
            {props.isDeskReportFlag ? (
              <Button
                primary
                disabled={reportButtonDisabled}
                onClick={() => {
                  if (deskNum.length === 0) {
                    setError(
                      "Desk Number is mandatory, enter the desk number which you are reporting"
                    );
                    setShowError(true);
                    return;
                  } else {
                    allDomains && report();
                  }
                }}
              >
                {props.reportButtonText}
              </Button>
            ) : (
              <Button
                primary
                disabled={reportButtonDisabled}
                onClick={() => {
                  report();
                }}
              >
                {props.reportButtonText}
              </Button>
            )}
          </div>
          <br />
          {showError && renderErrorMessage()}
        </Card>
        <div className="email-privacy-message-container text-sm">
          <Alert variant="light">
            Your email will be stored in our records for the support teams to
            contact you back if necessary. Please do not put any personal data
            like mobile or ECN number here
          </Alert>
        </div>
      </div>
      {showPairDeviceConfirmation && renderConfirmDevicePairingBox()}
    </>
  );
};

export default ReportBox;
