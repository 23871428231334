import React from "react";
import "./header.scss";

const Header = (props: any): JSX.Element => {
  const back = () => {
    if (props.back === undefined) {
      if (props.source && props.source === "workplace-app")
        window.open(
          `/web?id=${props.space.spaceId}&source=workplace-app&theme=${props.theme}&mail=${props.mail}&locationCode=${props.locationCode}`,
          "_self"
        );
      else window.open("/", "_self");
    } else {
      props.back();
    }
  };

  return (
    <>
      <div className="eq-box">
        <div className="eq-name">
          {props.isExtView ? null : props.isEquipmentBox ? null : (
            <i className="icon icon-arrow-left" onClick={back}></i>
          )}
          <i className="ericsson-icon icon icon-econ"></i>
        </div>

        <div>
          <div className="eq-id">
            <span className="text-lg">
              {props.space && props.space.spaceId}
            </span>
            &nbsp;
            {props.space.spaceName && (
              <>
                <span className="text-lg">{props.space.spaceName}</span>
                <br />
              </>
            )}
            {props.space.spaceType && (
              <>
                <span className="text-sm">{props.space.spaceType}</span>
                <br />
              </>
            )}
            {props.space.floor && (
              <>
                <br />
                <span className="text-sm">
                  {props.space.spaceId} - Floor {props.space.floor.slice(-2)}
                </span>
                <br />
              </>
            )}
          </div>
          <br />
          <div className="eq-id text-sm">Tell us what's wrong here?</div>
          <br />
        </div>
      </div>
    </>
  );
};

export default Header;
